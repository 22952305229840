@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.test-border {
  border: 1px solid rgb(255, 255, 255);
}

.mobile-menu-enter {
  transform: translateX(-100%);
}

.mobile-menu-enter-active {
  transform: translateX(0%);
  transition: transform 300ms ease-in-out;
}

.mobile-menu-exit {
  transform: translateX(0%);
}

.mobile-menu-exit-active {
  transform: translateX(-100%);
  transition: transform 300ms ease-in-out;
}
